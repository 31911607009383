import { Card, CardHeader, Divider, makeStyles, Title3 } from '@fluentui/react-components';
import { useFormContext } from 'react-hook-form';
import { ClientOnboardingForm, ClientType } from '../../../api';
import ResponsiveRowWrapper from '../../layout/ResponsiveRowWrapper';
import Stack from '../../layout/Stack';
import SsnInput from '../SsnInput';
import TextInput from '../TextInput';

const useStyles = makeStyles({
    cardContainer: {
        width: '100%',
    },
});

type GeneralPageProps = {
    clientType?: ClientType;
};

const GeneralInfoCard = ({ clientType }: GeneralPageProps) => {
    const styles = useStyles();
    const { control } = useFormContext<ClientOnboardingForm>();
    const showIndividual = clientType === ClientType.Individual || clientType === ClientType.Staff;

    return (
        <Card className={styles.cardContainer}>
            <CardHeader header={<Title3>General Information</Title3>} />
            <Stack flexDirection="column">
                {!showIndividual && (
                    <ResponsiveRowWrapper>
                        <TextInput
                            control={control}
                            name="generalSection.businessSection.businessName"
                            label="Business Name"
                            rules={{ required: true }}
                        />
                    </ResponsiveRowWrapper>
                )}
                {showIndividual && (
                    <>
                        <Divider alignContent="start" appearance="strong">
                            Main Individual
                        </Divider>
                        <ResponsiveRowWrapper>
                            <TextInput
                                control={control}
                                name="generalSection.individualSection.firstName"
                                label="First Name"
                                rules={{ required: true }}
                            />
                            <TextInput
                                control={control}
                                name="generalSection.individualSection.middleName"
                                label="Middle Name"
                            />
                            <TextInput
                                control={control}
                                name="generalSection.individualSection.lastName"
                                label="Last Name"
                                rules={{ required: true }}
                            />
                            <SsnInput control={control} name="generalSection.individualSection.ssn" label="SSN" />
                        </ResponsiveRowWrapper>
                        <Divider alignContent="start" appearance="strong">
                            Spouse Information
                        </Divider>
                        <ResponsiveRowWrapper>
                            <TextInput
                                control={control}
                                name="generalSection.individualSection.spouseFirstName"
                                label="First Name"
                            />
                            <TextInput
                                control={control}
                                name="generalSection.individualSection.spouseMiddleName"
                                label="Middle Name"
                            />
                            <TextInput
                                control={control}
                                name="generalSection.individualSection.spouseLastName"
                                label="Last Name"
                            />
                            <SsnInput
                                control={control}
                                name="generalSection.individualSection.spouseSsn"
                                label="Spouse SSN"
                            />
                        </ResponsiveRowWrapper>
                    </>
                )}
            </Stack>
        </Card>
    );
};

export default GeneralInfoCard;
