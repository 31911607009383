import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { ReactNode } from 'react';

const useStyles = makeStyles({
    responsiveContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: '1rem',
        width: '100%',

        '@media (min-width: 572px)': {
            flexDirection: 'row',
        },
    },
});

interface ResponsiveRowContainerProps {
    children: ReactNode;
    className?: string;
}

const ResponsiveRowWrapper = ({ children, className }: ResponsiveRowContainerProps) => {
    const styles = useStyles();

    return <div className={mergeClasses(styles.responsiveContainer, className)}>{children}</div>;
};

export default ResponsiveRowWrapper;
