import { Card, CardHeader, makeStyles, Title3 } from '@fluentui/react-components';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ClientOnboardingForm, ClientType } from '../../../api';
import { useBusinessUnitOptionsGet, useClientTypeOptionsGet, useOfficeOptionsGet } from '../../../api/hooks/OptionsApi';
import ResponsiveRowWrapper from '../../layout/ResponsiveRowWrapper';
import ATADSelectInput from '../ATADSelectInput';

const useStyles = makeStyles({
    cardContainer: {
        width: '100%',
    },
});

const clientTypeOptions = Object.entries(ClientType).map(([key, value]) => ({
    name: value,
    id: key as keyof typeof ClientType,
}));

const ClientTypeCard = () => {
    const styles = useStyles();
    const { control, watch, setValue } = useFormContext<ClientOnboardingForm>();
    const clientType = watch('clientType');

    const { data: businessUnitOptions } = useBusinessUnitOptionsGet();
    const { data: clientTypeSubOptions } = useClientTypeOptionsGet(clientType);
    const { data: officeOptions } = useOfficeOptionsGet();

    const shouldRenderSubTypes = useMemo(
        () => Boolean(clientTypeSubOptions && clientTypeSubOptions.length > 0),
        [clientTypeSubOptions]
    );

    // Use Effect to set default values for Business Unit and Office when Staff is selected
    useEffect(() => {
        if (clientType === ClientType.Staff) {
            // Automatically set the Business Unit when Staff is selected
            setValue(
                'generalSection.businessUnitId',
                businessUnitOptions?.find((bu) => bu.name.includes('Advisory'))?.id ?? ''
            );
            // Automatically set the Office when Staff is selected
            setValue('generalSection.officeId', officeOptions?.find((off) => off.name.includes('Admin'))?.id ?? '');
        }
    }, [clientType]);

    return (
        <Card className={styles.cardContainer}>
            <CardHeader header={<Title3>Client Type</Title3>} />
            <ResponsiveRowWrapper>
                <ATADSelectInput
                    name="clientType"
                    control={control}
                    rules={{ required: true }}
                    label="Client Type"
                    options={clientTypeOptions}
                />
                {shouldRenderSubTypes && (
                    <ATADSelectInput
                        name="clientSubTypeId"
                        control={control}
                        rules={{ required: false }}
                        label={`${clientType} Type`}
                        options={clientTypeSubOptions}
                    />
                )}
            </ResponsiveRowWrapper>
            <ResponsiveRowWrapper>
                <ATADSelectInput
                    control={control}
                    name="generalSection.officeId"
                    label="Office"
                    options={officeOptions ?? []}
                    rules={{ required: true }}
                />
                <ATADSelectInput
                    control={control}
                    name="generalSection.businessUnitId"
                    label="Business Unit"
                    options={businessUnitOptions ?? []}
                    disabled={clientType === ClientType.Staff}
                    rules={{ required: true }}
                />
            </ResponsiveRowWrapper>
        </Card>
    );
};

export default ClientTypeCard;
