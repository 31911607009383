import {
    Card,
    CardHeader,
    Checkbox,
    CheckboxOnChangeData,
    CheckboxProps,
    Divider,
    makeStyles,
    Title3,
} from '@fluentui/react-components';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ClientOnboardingForm, ClientType } from '../../../api';
import ResponsiveRowWrapper from '../../layout/ResponsiveRowWrapper';
import Stack from '../../layout/Stack';
import AddressRow from '../AddressRow';
import EmailInput from '../EmailInput';
import PhoneInputField from '../PhoneInput';

const useStyles = makeStyles({
    cardContainer: {
        width: '100%',
    },
});

type AddressPhoneCardProps = {
    clientType?: ClientType;
};

const AddressPhoneCard = ({ clientType }: AddressPhoneCardProps) => {
    const styles = useStyles();
    const { control } = useFormContext<ClientOnboardingForm>();
    const showIndividual = clientType === ClientType.Individual || clientType === ClientType.Staff;
    const [checked, setChecked] = useState<CheckboxProps['checked']>(true);

    const handleCheckbox = (_ev: React.ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        setChecked(data.checked);
    };

    // Use effect to see if the field is checked, then any edits made to the main address should be made to the mailing fields if the value of checked is true
    // const watchedStreet1 = watch('mainAddress.streetLine');
    // const watchedStreet2 = watch('mainAddress.streetLine2');
    // const watchedCity = watch('mainAddress.city');
    // const watchedState = watch('mainAddress.state');
    // const watchedZip = watch('mainAddress.zip');

    // useEffect(() => {
    //     if (checked) {
    //         if (watchedStreet1 !== watch('contactSection.mailingAddress.streetLine')) {
    //             setValue('contactSection.mailingAddress.streetLine', watchedStreet1);
    //         }
    //         if (watchedStreet2 !== watch('contactSection.mailingAddress.streetLine2')) {
    //             setValue('contactSection.mailingAddress.streetLine2', watchedStreet2);
    //         }
    //         if (watchedCity !== watch('contactSection.mailingAddress.city')) {
    //             setValue('contactSection.mailingAddress.city', watchedCity);
    //         }
    //         if (watchedState !== watch('contactSection.mailingAddress.state')) {
    //             setValue('contactSection.mailingAddress.state', watchedState);
    //         }
    //         if (watchedZip !== watch('contactSection.mailingAddress.zip')) {
    //             setValue('contactSection.mailingAddress.zip', watchedZip);
    //         }
    //     }
    // }, [checked, setValue, watch, watchedCity, watchedState, watchedStreet1, watchedStreet2, watchedZip]);

    return (
        <Card className={styles.cardContainer}>
            <CardHeader header={<Title3>Contact Information</Title3>} />
            <Stack flexDirection="column">
                <Divider alignContent="start">{showIndividual ? 'Home Address *' : 'Business Address *'}</Divider>
                <AddressRow name="contactSection.mainAddress" control={control} rules={{ required: true }} />
                <Divider alignContent="start">Mailing Address</Divider>
                <div>
                    <Checkbox
                        labelPosition="before"
                        label="Mailing Same as Above"
                        checked={checked}
                        onChange={handleCheckbox}
                    />
                </div>
                {!checked && <AddressRow control={control} name="contactSection.mailingAddress" />}
                <ResponsiveRowWrapper>
                    <PhoneInputField
                        control={control}
                        name="contactSection.mainPhone"
                        label={showIndividual ? 'Home/Main Phone' : 'Business Phone'}
                        rules={{ required: true }}
                    />
                    <PhoneInputField control={control} name="contactSection.mobilePhone" label={'Mobile Phone'} />
                    {showIndividual && (
                        <PhoneInputField
                            control={control}
                            name="contactSection.spouseMobilePhone"
                            label={'Spouse Mobile Phone'}
                        />
                    )}
                </ResponsiveRowWrapper>
                <ResponsiveRowWrapper>
                    <EmailInput
                        control={control}
                        name="contactSection.mainEmail"
                        label={showIndividual ? 'Personal Email' : 'Business Email'}
                        rules={{ required: true }}
                    />
                    {showIndividual && (
                        <>
                            <EmailInput control={control} name="contactSection.spouseEmail" label="Spouse Email" />
                            <EmailInput control={control} name="contactSection.businessEmail" label="Business Email" />
                        </>
                    )}
                </ResponsiveRowWrapper>
            </Stack>
        </Card>
    );
};

export default AddressPhoneCard;
