import { Address } from '../api';

export const addressPrinter = (address: Address | null | undefined): string => {
    if (!address) {
        return 'Address was entered incorrectly';
    }
    if (!address.streetLine) {
        return '';
    }
    if (!address.streetLine2) {
        return `${address.streetLine}, ${address.city}, ${address.state}, ${address.zip}`;
    } else {
        return `${address.streetLine} ${address.streetLine2}, ${address.city}, ${address.state}, ${address.zip}`;
    }
};

export const ssnPrinter = (ssn: string | null | undefined) => {
    if (!ssn) {
        return 'No SSN provided';
    }
    if (ssn.length < 9) {
        return "SSN doesn't have enough digits";
    }
    return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
};

export const einPrinter = (ein: string | null | undefined) => {
    if (!ein) {
        return 'No EIN provided';
    }
    if (ein.length < 9) {
        return "EIN doesn't have enough digits";
    }
    return ein.replace(/(\d{2})(\d{7})/, '$1-$2');
};
